import React, { useState,useEffect } from 'react';
//import Widgets from './Widgets'
import { IoIosArrowForward } from "react-icons/io";
//import { IoClose } from "react-icons/io5";
import { TiArrowForward } from "react-icons/ti";
import { FaStar } from "react-icons/fa";
import { BsFullscreen } from "react-icons/bs";
import PerformingTable from './PerformingTable';
import PerformingCountries from './PerformingCountries';
import DemographicTabs from './DemographicTabs';
//import trafficOverview from '../../assets/images/traffic-overview.svg'
import TableWithThead from './TableWithThead';
//import indFlag from '../../assets/images/ind.svg'
//import usFlag from '../../assets/images/usa.svg'
//import ukFlag from '../../assets/images/uk.svg'
//import mldFlag from '../../assets/images/mld.svg'
//import grmnFlag from '../../assets/images/grmn.svg'
//import geographicalDistribution from '../../assets/images/geographical-distribution.svg'
import totalGraph from '../../assets/images/user-tab-data.svg'
import userChart from '../../assets/images/user-graph.svg'
//import imgSession from '../../assets/images/session.svg'
//import imgDeviceTreffic from '../../assets/images/device-treffic.svg'
//import fbLogo from '../../assets/images/fb.svg'
import googleLogo from '../../assets/images/google.svg'
//import bingLogo from '../../assets/images/bing.svg'
import siteHealth from '../../assets/images/site-health.svg'
import { Link } from 'react-router-dom';

import { getActiveUsersByAgeAndCountrydata,getActiveUsersByGenderAndCountry,getActiveUsersByAgeAndCountry,getNewAndTotalUsersByDate,getGeographicalDistribution,getTrafficAcquisitionData,getUsersByDeviceCategory,getSessionbySearchEnginesData,getTopCountriesData,getTopPagesData,getAnalyticswidgetData,getGoogleSearchClickandImpressionsData } from "../dataService/DataService";
import { useQuery } from "react-query";
import DonutChart from "../../components/charts/DonutChart.js";
import GeoDistributionChart from '../../components/charts/GeoChart.js';
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { HiMiniArrowTrendingDown } from "react-icons/hi2";
import totalUsers from '../../assets/images/total-user.svg'
import newUsers from '../../assets/images/new-users.svg'
import totalClicks from '../../assets/images/total-clicks.svg'
import totalImpressions from '../../assets/images/total-impressions.svg'
import LineChartComponent from '../../components/charts/LineChart.js';

const NewDashboard = () => {
    // State to manage toggle
    const [isFilterClosed, setIsFilterClosed] = useState(false);
    const [rangeType, setRangeType] = useState("last7days");
    const [dummystartDate, setdummyStartDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
  
  
    const handleClick = (newRangeType) => {
        //console.log("newRangeType",newRangeType)
      setRangeType(newRangeType);
      setIsFilterClosed(false);
      setStartDate('');
      setdummyStartDate('');
      setEndDate('');
    };
    //   get getActiveUsersByAgeAndCountrydata data start
// let getActiveUsersByAgeAndCountrydata = useQuery("getActiveUsersByAgeAndCountry", getActiveUsersByAgeAndCountry);
const activeUsersByAgeAndCountrydata = useQuery(["ActiveUsersByAgeAndCountrydata", rangeType, startDate, endDate], () => getActiveUsersByAgeAndCountrydata(rangeType, startDate, endDate));

console.log("activeUsersByAgeAndCountrydata",activeUsersByAgeAndCountrydata)
//   get getActiveUsersByAgeAndCountry data end

useEffect(() => {
  if (activeUsersByAgeAndCountrydata?.data?.length > 0) {
    setSelectedCountry(activeUsersByAgeAndCountrydata?.data[0]);
  }
}, [activeUsersByAgeAndCountrydata?.data]);


    // TrafficAcquisitionData start
    const TrafficAcquisitionData = useQuery(["TrafficAcquisitionData", rangeType, startDate, endDate], () => getTrafficAcquisitionData(rangeType, startDate, endDate));
    // let TrafficAcquisitionData = useQuery("TrafficAcquisitionData", getTrafficAcquisitionData);
    // //console.log("getTrafficAcquisitionData",TrafficAcquisitionData.data?.channelData);
    const TrafficAcquisitionchartData = [
        ['Channel', 'Active Users'],
        ...(TrafficAcquisitionData.data?.channelData?.map(item => [item.channel, item.activeUsers]) || [])
      ];
    //   const TrafficAcquisitionchartoptions = {
    //     // title: 'Active Users by Channel',
    //      pieHole: 0.4, // This makes it a donut chart
    //      is3D: false,
    //      chartArea: { width: '90%', height: '75%' },
    //      legend: { position: 'right', alignment: 'center' },
    //    };
       const TrafficAcquisitionchartoptions = {
        // title: 'Active Users by Channel',
        pieHole: 0.4, // Creates the donut hole
        is3D: false,  // Makes the chart 2D
        chartArea: { width: '90%', height: '75%' }, // Adjusts the size of the chart area
        legend: { position: 'right', alignment: 'center' }, // Positions the legend
    colors: ['#FF8C67','#987BEC','#FF77A0',  '#69D3AD',  '#76C5FF', '#FFBF5F', '#A5A9D0'], // Array of colors for slices
        // colors: ['#FF6347', '#4682B4', '#32CD32', '#FFD700'], // Custom colors for the slices
        pieSliceText: 'label', // Show labels inside slices
        pieSliceBorderColor: 'white', // Border color of pie slices
      };
    //   TrafficAcquisitionData end

      // Usersby Device category start
    //   let UsersByDeviceCategory = useQuery("UsersByDeviceCategory", getUsersByDeviceCategory);
    const UsersByDeviceCategory = useQuery(["UsersByDeviceCategory", rangeType, startDate, endDate], () => getUsersByDeviceCategory(rangeType, startDate, endDate));

    //   //console.log("getTrafficAcquisitionData",TrafficAcquisitionData.data?.channelData);
      const UsersByDeviceCategorychartData = [
          ['Channel', 'Active Users'],
          ...(UsersByDeviceCategory.data?.deviceCategoryData?.map(item => [item.deviceCategory, item.users]) || [])
        ];
        // const UsersByDeviceCategorychartoptions = {
        //     // title: 'Active Users by Channel',
        //      pieHole: 0.4, // This makes it a donut chart
        //      is3D: false,
        //      chartArea: { width: '90%', height: '75%' },
        //      legend: { position: 'bottom', alignment: 'center' },
        //    };
           const UsersByDeviceCategorychartoptions = {
            // title: 'Active Users by Channel',
            pieHole: 0.4, // This makes it a donut chart
            is3D: false, // Makes the chart 2D
            chartArea: { width: '90%', height: '75%' }, // Adjusts the size of the chart area
            legend: { position: 'bottom', alignment: 'center' }, // Positions the legend at the bottom
            // colors: ['#FF5733', '#33FF57', '#3357FF', '#F0F033'], // Array of colors for slices
    // colors: ['#987BEC', '#FF77A0', '#69D3AD', '#FF8C67', '#76C5FF', '#FFBF5F', '#A5A9D0'], // Array of colors for slices
    colors: ['#FF8C67','#987BEC','#FF77A0',  '#69D3AD',  '#76C5FF', '#FFBF5F', '#A5A9D0'], // Array of colors for slices

        };
        
      //   Usersby Device category end

    //   get sessions by search engines start
    // let sessionbySearchEnginesData = useQuery("SessionbySearchEnginesData", getSessionbySearchEnginesData);
    const sessionbySearchEnginesData = useQuery(["SessionbySearchEnginesData", rangeType, startDate, endDate], () => getSessionbySearchEnginesData(rangeType, startDate, endDate));

    //   get sessions by search engines end

//   get top countries data start
// let topCountriesData = useQuery("TopCountriesData", getTopCountriesData);
const topCountriesData = useQuery(["TopCountriesData", rangeType, startDate, endDate], () => getTopCountriesData(rangeType, startDate, endDate));

// //console.log("topCountriesData",topCountriesData)
//   get top countries data end
    
//   get top pages data start
// let topPagesData = useQuery("TopPagesData", getTopPagesData);
const topPagesData = useQuery(["TopPagesData", rangeType, startDate, endDate], () => getTopPagesData(rangeType, startDate, endDate));

//  //console.log("topPagesData",topPagesData)
//   get top pages data end

//   get Analyticswidget data start
// let analyticswidgetData = useQuery("AnalyticswidgetData", getAnalyticswidgetData);
const analyticswidgetData = useQuery(["AnalyticswidgetData", rangeType, startDate, endDate], () => getAnalyticswidgetData(rangeType, startDate, endDate));

//  //console.log("analyticswidgetData",analyticswidgetData)
//   get Analyticswidget data end

//   get GoogleSearchClickandImpressionsData data start
// let googleSearchClickandImpressionsData = useQuery("GoogleSearchClickandImpressionsData", getGoogleSearchClickandImpressionsData);
const googleSearchClickandImpressionsData = useQuery(["GoogleSearchClickandImpressionsData", rangeType, startDate, endDate], () => getGoogleSearchClickandImpressionsData(rangeType, startDate, endDate));

//  //console.log("googleSearchClickandImpressionsData",googleSearchClickandImpressionsData)
//   get GoogleSearchClickandImpressionsData data end



//   get geographicalDistribution data start
// let geographicalDistribution = useQuery("GeographicalDistribution", getGeographicalDistribution);
const geographicalDistribution = useQuery(["GeographicalDistribution", rangeType, startDate, endDate], () => getGeographicalDistribution(rangeType, startDate, endDate));

//   //console.log("geographicalDistribution",geographicalDistribution)
//   get geographicalDistribution data end


//   get getNewAndTotalUsersByDate data start
// let getNewAndTotalUsersByDate = useQuery("GeographicalDistribution", getGeographicalDistribution);
const newAndTotalUsersByDate = useQuery(["NewAndTotalUsersByDate", rangeType, startDate, endDate], () => getNewAndTotalUsersByDate(rangeType, startDate, endDate));

  //console.log("getNewAndTotalUsersByDate",newAndTotalUsersByDate)
//   get getNewAndTotalUsersByDate data end

//   get getActiveUsersByGenderAndCountry data start
// let getActiveUsersByGenderAndCountry = useQuery("getActiveUsersByGenderAndCountry", getActiveUsersByGenderAndCountry);
const activeUsersByGenderAndCountry = useQuery(["ActiveUsersByGenderAndCountry", rangeType, startDate, endDate,selectedCountry], () => getActiveUsersByGenderAndCountry(rangeType, startDate, endDate,selectedCountry));

  console.log("getActiveUsersByGenderAndCountry",activeUsersByGenderAndCountry)
//   get getActiveUsersByGenderAndCountry data end



//   get getActiveUsersByAgeAndCountry data start
// let getActiveUsersByAgeAndCountry = useQuery("getActiveUsersByAgeAndCountry", getActiveUsersByAgeAndCountry);
const activeUsersByAgeAndCountry = useQuery(["ActiveUsersByAgeAndCountry", rangeType, startDate, endDate,selectedCountry], () => getActiveUsersByAgeAndCountry(rangeType, startDate, endDate,selectedCountry));

  console.log("getActiveUsersByAgeAndCountry",activeUsersByAgeAndCountry)
//   get getActiveUsersByAgeAndCountry data end


    // Function to handle the toggle
    const handleToggle = () => {
        setIsFilterClosed(!isFilterClosed);
        
    };
    // const widgetData = [
    //     { totalUser: 'Total Users', valueNumber: '+5.1%', largeValue: '44,000', increaseDecreaseIcon: true, iconClass: 'increase-value' },
    //     { totalUser: 'New Users', iconType: 'newusers', valueNumber: '+2.1%', largeValue: '32,000', increaseDecreaseIcon: true, iconClass: 'increase-value' },
    //     { totalUser: 'Total Organic Clicks', iconType: 'clicks', valueNumber: '+2.1%', largeValue: '946', increaseDecreaseIcon: true, iconClass: 'increase-value' },
    //     { totalUser: 'Total Organic Impressions', iconType: 'impressions', valueNumber: '-1%', largeValue: '10,000', increaseDecreaseIcon: false, iconClass: 'decrease-value' }
    // ]

    const demographData = [
        { coverId: 0, tabName: 'India', totalUserGraph: totalGraph, totalUserChart: userChart },
        { coverId: 1, tabName: 'USA', totalUserGraph: totalGraph, totalUserChart: userChart },
        { coverId: 2, tabName: 'UK', totalUserGraph: totalGraph, totalUserChart: userChart }
    ]

    const tableTheadData = [
        { tableTheadName: 'Search Engine' },
        { tableTheadName: 'Users' },
        { tableTheadName: 'Sessions' },
        { tableTheadName: 'Eng. Sessions' },
        { tableTheadName: 'Eng. rate' }
    ]
// Handle end date change
const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setRangeType('abc'); // Update rangeType when endDate changes
    setStartDate(dummystartDate);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setdummyStartDate(newStartDate)

    if (endDate){
    setStartDate(newStartDate);
    }
    // Reset end date if it's before the new start date
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate('');
    }
  };
  //console.log("rangeType",rangeType)
  //console.log("startDate",startDate)
  //console.log("endDate",endDate)

    return (
        <>
            <div className='page-body'>

                <div className='analystic-widgets'>
                    <div className='row'>
                        <div className='col-xl-3 mb-4'>
                            <h4>Web Analytics</h4>
                        </div>
                        <div className='col-xl-9 mb-xl-0 mb-4'>
                            <div className='d-flex align-items-center justify-content-end mb-lg-0 mb-md-4 flex-lg-nowrap flex-wrap'>
                                <div className='filter-btns-group position-relative pe-4 me-4'>
                                    <button value="last7days" className={`btn btn-outline fw-semibold ${rangeType === 'last7days' ? 'active' : ''}`}  onClick={() => handleClick('last7days')}>Last 7 days</button>
                                    <button value="last30days" className={`btn btn-outline fw-semibold ms-2 ${rangeType === 'last30days' ? 'active' : ''}`}  onClick={() => handleClick('last30days')}>Last 30 days</button>
                                    <button value="last3Months" className={`btn btn-outline fw-semibold ms-2 ${rangeType === 'last3Months' ? 'active' : ''}`}  onClick={() => handleClick('last3Months')}>Last 3 months</button>
                                </div>
                                <div className={`filter-btns-group custom-filter position-relative overflow-hidden ${isFilterClosed ? 'filter-toggle' : ''}`}>
                                    <button className='btn btn-outline fw-semibold'  onClick={handleToggle}>
                                        Custom Filters <IoIosArrowForward />
                                    </button>
                                    <form action='#' method='get' className='d-flex align-items-center'>
                                        <div className='custom-date-filters d-flex'>
                                            <div className='form-group'>
                                                <input placeholder='From' type='date' className='form-control'   value={dummystartDate}
           onChange={handleStartDateChange} />
                                            </div>
                                            <div className='form-group ms-2'>
                                                <input placeholder='To' type='date' className='form-control' value={endDate}
              min={dummystartDate} // Disable dates earlier than the start date
             onChange={handleEndDateChange}
             disabled={!dummystartDate} />
                                            </div>
                                        </div>
                                        {/* <div className='form-group'>
                                            <button
                                                type='button'
                                                className='close btn border-0 p-1 bg-transparent text-secondary ms-2'
                                                onClick={handleToggle}
                                            >
                                                <IoClose className='h5 mb-0' />
                                            </button>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /row */}

                    <div className='row'>
                        <div className='left-sidebar-cols w-100'>

                            <div className='row'>
                               {/* {analyticswidgetData && analyticswidgetData?.data && googleSearchClickandImpressionsData && googleSearchClickandImpressionsData?.data ? <Widgets widgetData={analyticswidgetData?.data} clicksData={googleSearchClickandImpressionsData?.data} />:""} */}
                               <div className='col-xxl-3 col-md-6 mb-4'>
                <div className='card border-0 widget-card' >
                    <div className='card-top-head mb-3 d-flex align-items-center justify-content-between'>
                        <span className='anylystic-user'>
                           Total Users
                        </span>
                        <span className={`value-stats ${analyticswidgetData?.data?.comparisonPercentage.totalUsers>=0? 'increase-value':'decrease-value'}`}>
                            { analyticswidgetData && analyticswidgetData?.data && analyticswidgetData?.data?.comparisonPercentage && analyticswidgetData?.data?.comparisonPercentage?.totalUsers ? analyticswidgetData?.data?.comparisonPercentage?.totalUsers>=0? '+'+analyticswidgetData?.data?.comparisonPercentage?.totalUsers:''+analyticswidgetData?.data?.comparisonPercentage?.totalUsers :"" }
                            { analyticswidgetData && analyticswidgetData?.data && analyticswidgetData?.data?.comparisonPercentage && analyticswidgetData?.data?.comparisonPercentage?.totalUsers ? analyticswidgetData?.data?.comparisonPercentage.totalUsers>=0 ? (
                            <HiMiniArrowTrendingUp className='ms-1 increase-icon' />
                            ) : (
                            <HiMiniArrowTrendingDown className='ms-1 decrease-icon' />
                            ):""}
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                    <div className='anylystic-card-icon'>
                        <img src={totalUsers} alt='Total Users' />
                    </div>
                    <div className='anylystic-card-value ms-3'>
                        <h2>{analyticswidgetData?.data?.primary?.totalUsers}</h2>
                    </div>
                    </div>
                </div>
              </div>

              <div className='col-xxl-3 col-md-6 mb-4'>
                <div className='card border-0 widget-card' >
                    <div className='card-top-head mb-3 d-flex align-items-center justify-content-between'>
                        <span className='anylystic-user'>
                           New Users
                        </span>
                        <span className={`value-stats ${analyticswidgetData?.data?.comparisonPercentage.newUsers>=0? 'increase-value':'decrease-value'}`}>
                            {analyticswidgetData?.data?.comparisonPercentage ?analyticswidgetData?.data?.comparisonPercentage.newUsers>=0? '+'+analyticswidgetData?.data?.comparisonPercentage.newUsers:''+analyticswidgetData?.data?.comparisonPercentage.newUsers:"" }
                            {analyticswidgetData?.data?.comparisonPercentage ? analyticswidgetData?.data?.comparisonPercentage.newUsers>=0 ? (
                            <HiMiniArrowTrendingUp className='ms-1 increase-icon' />
                            ) : (
                            <HiMiniArrowTrendingDown className='ms-1 decrease-icon' />
                            ):""}
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                    <div className='anylystic-card-icon'>
                        <img src={newUsers} alt='Total Users' />
                    </div>
                    <div className='anylystic-card-value ms-3'>
                        <h2>{analyticswidgetData?.data?.primary.newUsers}</h2>
                    </div>
                    </div>
                </div>
              </div>
            
         

              <div className='col-xxl-3 col-md-6 mb-4'>
                <div className='card border-0 widget-card' >
                    <div className='card-top-head mb-3 d-flex align-items-center justify-content-between'>
                        <span className='anylystic-user'>
                        Total Organic Clicks
                        </span>
                        <span className={`value-stats ${googleSearchClickandImpressionsData?.data?.comparisonPercentage.clicks>=0? 'increase-value':'decrease-value'}`}>
                            {googleSearchClickandImpressionsData?.data?.comparisonPercentage ? googleSearchClickandImpressionsData?.data?.comparisonPercentage.clicks>=0? '+'+googleSearchClickandImpressionsData?.data?.comparisonPercentage.clicks:''+googleSearchClickandImpressionsData?.data?.comparisonPercentage.clicks :"" }
                            {googleSearchClickandImpressionsData?.data?.comparisonPercentage ? googleSearchClickandImpressionsData?.data?.comparisonPercentage.clicks>=0 ? (
                            <HiMiniArrowTrendingUp className='ms-1 increase-icon' />
                            ) : (
                            <HiMiniArrowTrendingDown className='ms-1 decrease-icon' />
                            ):""}
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                    <div className='anylystic-card-icon'>
                        <img src={totalClicks} alt='Total Users' />
                    </div>
                    <div className='anylystic-card-value ms-3'>
                        <h2>{googleSearchClickandImpressionsData?.data?.primary.totalClicks}</h2>
                    </div>
                    </div>
                </div>
              </div>

              <div className='col-xxl-3 col-md-6 mb-4'>
                <div className='card border-0 widget-card' >
                    <div className='card-top-head mb-3 d-flex align-items-center justify-content-between'>
                        <span className='anylystic-user'>
                        Total Organic Impressions
                        </span>
                        <span className={`value-stats ${googleSearchClickandImpressionsData?.data?.comparisonPercentage.impressions>=0? 'increase-value':'decrease-value'}`}>
                            {googleSearchClickandImpressionsData?.data?.comparisonPercentage ? googleSearchClickandImpressionsData?.data?.comparisonPercentage.impressions>=0? '+'+googleSearchClickandImpressionsData?.data?.comparisonPercentage.impressions:''+googleSearchClickandImpressionsData?.data?.comparisonPercentage.impressions :""}
                            {googleSearchClickandImpressionsData?.data?.comparisonPercentage ? googleSearchClickandImpressionsData?.data?.comparisonPercentage.impressions>=0 ? (
                            <HiMiniArrowTrendingUp className='ms-1 increase-icon' />
                            ) : (
                            <HiMiniArrowTrendingDown className='ms-1 decrease-icon' />
                            ):""}
                        </span>
                    </div>
                    <div className='d-flex align-items-center'>
                    <div className='anylystic-card-icon'>
                        <img src={totalImpressions} alt='Total Users' />
                    </div>
                    <div className='anylystic-card-value ms-3'>
                        <h2>{googleSearchClickandImpressionsData?.data?.primary.totalImpressions}</h2>
                    </div>
                    </div>
                </div>
              </div>
            
                              
                               {/* {analyticswidgetData && analyticswidgetData?.data?  <Widgets widgetData={analyticswidgetData?.data} />:""} */}
                               {/* googleSearchClickandImpressionsData */}
                            </div>
                            {/* /row */}

                            <div className='row'>
                                <div className='col-xxl-6 mb-4 mb-lg-0'>
                                    <div className='card h-100'>
                                        <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                            <h6 className='fw-semibold text-capitalize mb-0'>Traffic Overview</h6>
                                            <div className='full-view'>
                                                <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                            </div>
                                        </div>
                                        <div className='card-body px-0 pb-0'>
                                            <div className='treffic-overview text-center'>
                                                {/* <img src={trafficOverview} alt='' /> */}
            {newAndTotalUsersByDate && newAndTotalUsersByDate?.data && newAndTotalUsersByDate?.data?.data ? <LineChartComponent data={newAndTotalUsersByDate?.data?.data} /> :""}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /col-lg-6 */}
                                <div className='col-xxl-6 mt-xxl-0 mt-4'>
                                    <div className='card h-100 analystic-table'>
                                        <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                            <h6 className='fw-semibold text-capitalize mb-0'>Top Performing Pages</h6>
                                            <div className='full-view'>
                                                <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                            </div>
                                        </div>
                                        <div className='card-body px-0 pb-0'>
                                            <div className='treffic-overview default-bg-table'>
                                              {topPagesData && topPagesData?.data && topPagesData.data?.topPages ? <PerformingTable tableRowData={topPagesData.data?.topPages} /> :""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /col-lg-6 */}
                            </div>
                            {/* /row */}
                            <div className='row mt-4'>
                                <div className='col-xxl-4 mb-4 mb-lg-0'>
                                    <div className='card h-100'>
                                        <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                            <h6 className='fw-semibold text-capitalize mb-0'>Top Performing Countries</h6>
                                            <div className='full-view'>
                                                <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                            </div>
                                        </div>
                                        <div className='card-body px-0 pb-0'>
                                            <div className='perfoming-countries default-bg-table'>
                                            {topCountriesData && topCountriesData?.data && topCountriesData?.data?.topCountries ? <PerformingCountries tableRowItems={topCountriesData?.data?.topCountries} /> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /col-lg-4 */}
                                <div className='col-xxl-8 mt-xxl-0 mt-4'>
                                    <div className='card h-100 analystic-table'>
                                        <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                            <h6 className='fw-semibold text-capitalize mb-0'>Geographical Distribution</h6>
                                            <div className='full-view'>
                                                <button className='btn export-btn btn-info fw-semibold ms-2'><TiArrowForward /> Export</button>
                                            </div>
                                        </div>
                                        <div className='card-body px-0 pb-0'>
                                            <div className='geographical-distribution my-4'>
                                                {/* <img src={geographicalDistribution} alt='' /> */}
                                          {geographicalDistribution && geographicalDistribution?.data && geographicalDistribution?.data?.distribution ? <GeoDistributionChart data={geographicalDistribution?.data?.distribution} /> :""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* /col-lg-6 */}
                            </div>
                            {/* /row */}

                        </div>
                        {/* /col-lg-9 */}

                        <div className='sidebar-cols w-100'>
                            <div className='card border-0 analystic-table'>
                                <div className='card-body px-0 py-2 text-center'>
                                    <div className='site-health d-flex justify-content-center'>
                                        <img src={siteHealth} alt='' />
                                    </div>
                                    <span className='small-title'>Site Health</span>
                                </div>
                            </div>
                            {/* /card */}
                            <div className='card border-0 mt-4 pt-1 analystic-table'>
                                <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                    <div className='d-flex align-items-center pt-4 pb-2'>
                                        <h6 className='fw-semibold text-capitalize mb-0'>
                                            <span className='me-2'>
                                                <img width={24} src={googleLogo} alt='' />
                                            </span>
                                            Google Reviews
                                        </h6>
                                    </div>
                                    <div className='full-view'>
                                        <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                    </div>
                                </div>
                                <div className='card-body py-2 text-center pb-0'>
                                    <div className='google-reviews gap-1 d-flex justify-content-center align-items-center'>
                                        <Link className='d-flex' to=''><FaStar /></Link>
                                        <Link className='d-flex' to=''><FaStar /></Link>
                                        <Link className='d-flex' to=''><FaStar /></Link>
                                        <Link className='d-flex' to=''><FaStar /></Link>
                                        <Link className='d-flex' to=''><FaStar /></Link>
                                        <span className='ms-4 h2 mb-0 text-black'>4.5</span>
                                    </div>
                                </div>
                            </div>
                            {/* /card */}
                            <div className='card border-0 upgrade-service mt-4'>
                                <h5 className='text-white mt-1'>Upgrade to Unlock SEO Services</h5>
                                <p className='text-white my-3'>Upgrade Now for Premium Access and Unlock Exclusive Feature!</p>
                                <div className='upgrade-action mt-3 mb-3'>
                                    <Link to='' className='btn btn-secondary rounded-5 bg-white border-0 text-black'>Upgrade Now</Link>
                                </div>
                            </div>
                            {/* /card */}
                            <div className='card border-0 booking-card mt-4'>
                                <div className='card-body px-0'>
                                    <div className='d-flex align-items-center justify-content-between my-1'>
                                        <div className='left-card-text'>
                                            <span>Bookings</span>
                                            <span>lorem ipsum</span>
                                        </div>
                                        <div className='card-large-value google-reviews mt-1'>
                                            <h2 className='mb-0 h2'>346</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /card */}
                            <div className='card border-0 enquiry-card mt-4'>
                                <div className='card-body px-0'>
                                    <div className='d-flex align-items-center justify-content-between my-1'>
                                        <div className='left-card-text'>
                                            <span>No. of</span>
                                            <span>Enquiries</span>
                                        </div>
                                        <div className='card-large-value google-reviews mt-1'>
                                            <h2 className='mb-0 h2'>25</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /card */}
                        </div>

                    </div>
                    {/* /row */}
                    <div className='row mt-4'>
                        <div className='col-xl-8 mb-4 mb-xl-0'>
                            <div className='card h-100'>
                                <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                    <h6 className='fw-semibold text-capitalize mb-0'>Demographic Overview by Age & Gender</h6>
                                    <div className='full-view'>
                                        <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                    </div>
                                </div>
                                <div className='card-body pt-0 px-0'>
                                    <div className='overview-tabs'>
                                        <DemographicTabs selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} activeUsersByAgeAndCountrydata={activeUsersByAgeAndCountrydata} tabArray={demographData} activeUsersByAgeAndCountry={activeUsersByAgeAndCountry} activeUsersByGenderAndCountry={activeUsersByGenderAndCountry} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-8 */}
                        <div className='col-xl-4'>
                            <div className='card h-100'>
                                <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                    <h6 className='fw-semibold text-capitalize mb-0'>Sessions</h6>
                                    <div className='full-view'>
                                        <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                    </div>
                                </div>
                                <div className='card-body px-0 pt-0'>
                                    <div className='perfoming-countries h-100 d-flex align-items-center justify-content-center'>
                                        {/* <img src={imgSession} alt='' /> */}
                                       {TrafficAcquisitionchartData ? <DonutChart chartData={TrafficAcquisitionchartData} options={TrafficAcquisitionchartoptions} /> :""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                    </div>
                    {/* /row */}
                    <div className='row mt-4'>
                        <div className='col-xl-8 mb-4 mb-xl-0'>
                            <div className='card h-100'>
                                <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                    <h6 className='fw-semibold text-capitalize mb-0'>Sessions by Search Engines</h6>
                                    <div className='full-view'>
                                        <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                    </div>
                                </div>
                                <div className='card-body pt-4 px-0 pb-0'>
                                    <div className='session-search-table default-bg-table'>
                                      {sessionbySearchEnginesData && sessionbySearchEnginesData?.data && sessionbySearchEnginesData.data?.data ?  <TableWithThead tableTheadData={tableTheadData} tableRowDataItem={sessionbySearchEnginesData.data?.data} />:""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-8 */}
                        <div className='col-xl-4'>
                            <div className='card h-100'>
                                <div className='card-header px-0 bg-transparent border-0 d-flex align-items-center justify-content-between pt-0'>
                                    <h6 className='fw-semibold text-capitalize mb-0'>Sessions</h6>
                                    <div className='full-view'>
                                        <Link to=''><BsFullscreen className='h6 mb-0' /></Link>
                                    </div>
                                </div>
                                <div className='card-body px-0 pt-0 py-lg-4 my-lg-2'>
                                    <div className='perfoming-countries h-100 d-flex align-items-center justify-content-center'>
                                        {/* <img src={imgDeviceTreffic} alt='' /> */}
                                       {UsersByDeviceCategorychartData ? <DonutChart chartData={UsersByDeviceCategorychartData} options={UsersByDeviceCategorychartoptions} /> :""}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /col-lg-4 */}
                    </div>
                    {/* /row */}
                </div>
            </div>
        </>
    )
}

export default NewDashboard