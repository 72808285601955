import React from 'react'
import BarChartComponent from '../../components/charts/BarChart'
import DonutChartGender from '../../components/charts/DonutChartGender'

function DemographicTabItem({totalUserGraph='',totalUserChart='',activeUsersByAgeAndCountry,activeUsersByGenderAndCountry}) {
 // console.log("activeUsersByGenderAndCountry",activeUsersByGenderAndCountry?.data)
   // Transform the filtered data into the format required by Google Charts
   const chartData = [
    ['Gender', 'Active Users'], // Header row
   ...activeUsersByGenderAndCountry?.data.map(item => [item.gender, item.activeUsers])
  ];

  // Define chart options
  const options = {
    //title: 'Active Users by Gender in the United Kingdom',
    pieHole: 0.4, // Creates the donut hole
    chartArea: { width: '50%' },
    legend: { position: 'bottom' },
    colors: ['#FF8C67','#987BEC','#FF77A0',  '#69D3AD',  '#76C5FF', '#FFBF5F', '#A5A9D0'], // Array of colors for slices

    // colors: ['#987BEC', '#FF77A0', '#69D3AD', '#FF8C67', '#76C5FF', '#FFBF5F', '#A5A9D0'], // Array of colors for slices
    pieSliceText: 'label',  // Show labels inside slices
    pieSliceBorderColor: 'white',  // Border color of pie slices
  };
  // const options = {
  //   //title: 'Active Users by Gender in the United Kingdom',
  //   pieHole: 0.4, // Creates the donut hole
  //   chartArea: { width: '50%' },
  //   legend: { position: 'bottom' },
  // };
  return (
    <>
        <div className='d-lg-flex align-items-center justify-content-between mt-lg-4 mt-3'>
            <div className='total-user-chart pe-lg-4 text-lg-start text-center'>
                {/* <img src={totalUserGraph} alt='' /> */}
               {activeUsersByAgeAndCountry && activeUsersByAgeAndCountry?.data ? <BarChartComponent data={activeUsersByAgeAndCountry?.data} />:""}
            </div>
            <div className='v-border d-lg-block d-none'></div>
            <div className='users-chart-view ps-lg-4 mt-lg-0 mt-4 text-lg-start text-center'>
            {activeUsersByAgeAndCountry && activeUsersByAgeAndCountry?.data ? <DonutChartGender chartData={chartData} options={options} />:""}
            </div>
        </div>
    </>
  )
}

export default DemographicTabItem