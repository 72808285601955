import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import * as querydata from "../../services/service";
import { Table } from "../../components/dragTable/Table";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Form } from "react-bootstrap";
import {
  getAllAwards,
  updateAwardSorting,
  updateAwardsIsActive,
  getResortName,
} from "../dataService/DataService";
import { useQuery } from "react-query";
import EditAwards from "./EditAwards";
import DeleteAwardsModal from "./DeleteAwardsModal";

export const awardsSchema = Yup.object({
  name: Yup.string().trim().required("Please enter award name"),
  awardee: Yup.string().trim().required("Please enter awardee"),
  year: Yup.number().required("Please select year"),
});
function ManageAwards() {
  const queryClient = useQueryClient();
  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteIdFromCookie = getCookie("websiteId").toString();
  const websiteNameFromCookie = decodeURIComponent(getCookie("websiteName"));

  const [showDelModal, setShowDelModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showMessages, setMessages] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [editId, setEditId] = useState("");
  const resortName = useQuery("getResortName", getResortName);
  const resortOptions = resortName?.data?.data;
  const [selectedResort, setSelectedResort] = useState("");
  const defaultWebsiteId = resortOptions?.map(
    (option) => option.website_id
  )?.[0];
  const handleSelectChange = (e) => {
    setSelectedResort(e.target.value);
  };

  const selectedWebsiteId =
    websiteNameFromCookie === "Ozen Collection" ||websiteNameFromCookie === "AHR Brand Website"
      ? selectedResort
        ? parseInt(selectedResort)
        : defaultWebsiteId
      : websiteIdFromCookie;

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const allAwards = useQuery(["getAllAwards", selectedWebsiteId], () =>
    getAllAwards(selectedWebsiteId)
  );

  const awardsData = allAwards?.data?.data?.filter(
    (item) => item.website_id === selectedWebsiteId
  );

  const years = [];
  const currentYear = new Date().getFullYear() + 1;
  for (let year = 2000; year <= currentYear; year++) {
    years.push(year);
  }
  const initialValues = {
    name: "",
    awardee: "",
    year: "",
  };
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [actions, setAction] = useState();
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    setTouched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: awardsSchema,
    onSubmit: (values, action) => {
      const trimmedValues = {
        ...values,
        name: values.name.trim(),
        awardee: values.awardee.trim(),
        websiteIds: selectedWebsiteId,
      };
      if (actions === "PREVIEW") {
        preview.mutate({ trimmedValues, websiteIdFromCookie });
      }
      if (actions === "SAVE") {
        addnewprojectMutation.mutate(trimmedValues);
      }
    },
  });

  const preview = useMutation(querydata.previewAwards, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      const websiteUrl = `${data.previewURL}?isawardspreview=true&uid=${data.encryptedUserId}&selectedWebsiteId=${selectedWebsiteId}`;
      window.open(websiteUrl, "_blank");
    },
    onSettled: () => {
      setIsPreviewLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsPreviewLoading(true); // Set loading to true before making the request
    },
  });
  const addnewprojectMutation = useMutation(querydata.insertAward, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      setShowMessageModal(false);
      setValues({
        name: "",
        awardee: "",
        year: "",
      });
      setTouched({});
      queryClient.invalidateQueries("getAllAwards");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });
  const handleViewMessageModal = (message) => {
    setMessages(message);

    setShowMessageModal(true);
  };
  const closeMessageModal = () => {
    setShowMessageModal(false);
    setValues({
      name: "",
      awardee: "",
      year: "",
    });
    setTouched({});
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const handleShowDelModal = (row) => {
    setSelectedRowData(row.original.award_id);
    setSelectedRowNameData(row.original.name);
    setShowDelModal(true);
  };
  const handleCloseDelModal = () => {
    setShowDelModal(false);
  };

  const handleNavigateToEdit = (awardId) => {
    setEditId(awardId);
    setShowEditModal(true);
  };
  const handleStatus = (e, row) => {
    const WebsiteId = row.original.website_id;
    const status = e.target.value;
    const id = row.original.award_id;

    row.original.status = status;
    changeApplicationStatusMutate.mutate({ id, status, WebsiteId });
  };

  const changeApplicationStatusMutate = useMutation(updateAwardsIsActive, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();
      toast.success(data.message);
    },
  });
  const columns = React.useMemo(() => {
    const baseColumns = [
      { Header: "Sr. No", accessor: "srNo" },
      { Header: "Name", accessor: "name" },
      { Header: "Year", accessor: "year" },
      { Header: "Awardee", accessor: "awardee" },
    ];

    if (websiteNameFromCookie === "Ozen Collection" || websiteNameFromCookie === "AHR Brand Website") {
      baseColumns.push({
        Header: "Resort",
        accessor: "resort_name",
      });
    }
    baseColumns.push(
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.status}
            className="form-select"
            style={{ width: "120px" }}
          >
            <option key="1" value="1">
              Active
            </option>
            <option key="0" value="0">
              InActive
            </option>
          </select>
        ),
      },

      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleNavigateToEdit(row.original.award_id)}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowDelModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      }
    );
    return baseColumns;
  }, [websiteIdFromCookie]);

  if (!allAwards?.data) {
    <div>Loading</div>;
  }
  if (allAwards.isLoading) {
    <div>Loading</div>;
  }

  useEffect(() => {
    if (allAwards?.data) {
      setData(allAwards?.data?.data);
    }
  }, [allAwards]);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Manage Awards</h4>
            </div>
          </div>
          {websiteNameFromCookie === "Ozen Collection" || websiteNameFromCookie === 'AHR Brand Website'  && (
            <div className="col-lg-3">
              <div className="mb-4">
                <label className="form-label" htmlFor="OfferType">
                  Resort
                </label>
                <select
                  name="resortId"
                  id="OfferType"
                  className="form-control form-select py-3 shadow-none px-4"
                  onChange={handleSelectChange}
                >
                  {resortOptions?.map((option) => (
                    <option key={option.website_id} value={option.website_id}>
                      {option.resort_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">All Awards</h5>
                  <div className="right-actions">
                    <Button
                      onClick={() => handleViewMessageModal()}
                      className="btn btn-primary btn-theme fw-semibold text-uppercase ms-2 px-3 py-2"
                    >
                      Add New
                    </Button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="tabledata">
                    <div className="table-responsive">
                      {allAwards?.data && (
                        <>
                          {websiteNameFromCookie === "Ozen Collection"||websiteNameFromCookie === 'AHR Brand Website' ? (
                            allAwards.isLoading || !allAwards?.data ? (
                              <div className="text-center">Loading...</div>
                            ) : awardsData?.length === 0 ? (
                              <div className="card-body text-center">
                                No Data Available
                              </div>
                            ) : (
                              <>
                                <Table
                                  data={awardsData}
                                  setData={setData}
                                  columns={columns}
                                  isShowingHeading={false}
                                  updateSortingTable={updateAwardSorting}
                                  componentName={"awards"}
                                />
                              </>
                            )
                          ) : allAwards.isLoading || !allAwards?.data ? (
                            <div className="text-center">Loading...</div>
                          ) : data?.length === 0 ? (
                            <div className="card-body text-center">
                              No Data Available
                            </div>
                          ) : (
                            <>
                              <Table
                                data={data}
                                setData={setData}
                                columns={columns}
                                isShowingHeading={false}
                                updateSortingTable={updateAwardSorting}
                                componentName={"awards"}
                              />
                            </>
                          )}
                        </>
                      )}

                      <DeleteAwardsModal
                        show={showDelModal}
                        handleClose={handleCloseDelModal}
                        rowData={selectedRowData}
                        rowNameData={selectedRowNameData}
                        selectedWebsiteId={selectedWebsiteId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* START  DIALOG MODAL */}

      <Modal show={showMessageModal} onHide={closeMessageModal} centered>
        <Modal.Header closeButton className="px-5 py-4">
          <Modal.Title as="h1" className="fs-5">
            Awards
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-5">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="name">Award Name:</Form.Label>
              <textarea
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                id="name"
                className="form-control py-3 shadow-none px-4"
                cols="30"
                rows="3"
                maxLength={120}
              ></textarea>

              {errors.name && touched.name ? (
                <p className="form-error text-danger">{errors.name}</p>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label htmlFor="awardee">Awardee:</Form.Label>
              <Form.Control
                type="text"
                id="awardee"
                className="py-3 shadow-none px-4"
                value={values.awardee}
                onChange={handleChange}
                maxLength={70}
              />
              {errors.awardee && touched.awardee ? (
                <p className="form-error text-danger">{errors.awardee}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="year">Year:</Form.Label>
              <select
                className="form-control py-3 shadow-none px-4"
                id="year"
                value={values.year}
                onChange={handleChange}
              >
                <option value="">---Select---</option>
                {years.map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
              {errors.year && touched.year ? (
                <p className="form-error text-danger">{errors.year}</p>
              ) : null}
            </Form.Group>
            <div className="text-end">
              <Button
                variant="outline-primary"
                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                onClick={closeMessageModal}
              >
                Cancel
              </Button>
              <Button
                variant="outline-primary"
                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                onClick={(e) => {
                  e.preventDefault();
                  setAction("PREVIEW");
                  handleSubmit();
                }}
              >
                {isPreviewLoading ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                ) : (
                  "PREVIEW"
                )}
              </Button>
              <Button
                variant="primary"
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setAction("SAVE");
                  handleSubmit();
                }}
              >
                {isLoading ? (
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                ) : (
                  "SAVE"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* END  DIALOG MODAL */}
      {showEditModal ? (
        <EditAwards
          editId={editId}
          showEditModal={showEditModal}
          closeEditModal={closeEditModal}
          years={years}
          setShowEditModal={setShowEditModal}
          selectedWebsiteId={selectedWebsiteId}
          websiteIdFromCookie={websiteIdFromCookie}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ManageAwards;
