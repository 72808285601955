import React from 'react';
import { Chart } from 'react-google-charts';

const GeoChartComponent = ({ data }) => {
    // console.log("data",data)
    // Prepare the data for GeoChart
    const geoChartData = [
        ['Country', 'User Count'], // Header
        ...Object.values(data).flatMap(group =>
            group.map(item => [item.country, item.userCount])
        )
    ];

    // GeoChart options
    const options = {
        colorAxis: { colors: ['#E6E5FF','#4D4BC6' ] }, // Color gradient
        backgroundColor: '#ffffff', // Background color
        datalessRegionColor: '#F3F2F9', // Color for regions with no data
        defaultColor: '#f5f5f5', // Default color
    };

    return (
        <div>
            <Chart
                chartType="GeoChart"
                width="100%"
                height="250px"
                data={geoChartData}
                options={options}
                mapsApiKey="AIzaSyA0uQvSt_ilKhPQozxm3nzlro7JowDoWBE"
            />
        </div>
    );
};

export default GeoChartComponent;

// import React, { useEffect } from 'react';
// import { Chart } from 'react-google-charts';

// const GeoDistributionChart = ({ distribution }) => {
//   const data = [
//     ['Country', 'User Count'],
//     ...distribution['up to 5000 users'].map(item => [item.country, item.userCount])
//   ];

//   const options = {
//     colorAxis: { colors: ['#e0f7fa', '#00796b'] }, // Customize color scale
//     backgroundColor: '#81d4fa', // Customize background color
//     datalessRegionColor: '#f5f5f5', // Color for regions with no data
//     defaultColor: '#f5f5f5', // Default color
//   };

//   return (
//     <Chart
//       chartType="GeoChart"
//       width="100%"
//       height="400px"
//       data={data}
//       options={options}
//     />
//   );
// };

// export default GeoDistributionChart;
