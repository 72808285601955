import React,{useEffect} from "react";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "../../../node_modules/react-tabs-scrollable/dist/rts.css";
import DemographicTabItem from "./DemographicTabItem";

function DemographicTabs({tabArray=[],activeUsersByAgeAndCountry,activeUsersByGenderAndCountry,activeUsersByAgeAndCountrydata,selectedCountry, setSelectedCountry}) {
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
        setActiveTab(index);
    };
    
    const handleClick = (country) => {
        setSelectedCountry(prevSelectedCountry => {
            const selectedCountriesArray = prevSelectedCountry.split(',').filter(c => c);
    
            // Check if the country is already selected
            if (selectedCountriesArray.includes(country)) {
                // If the country is already selected, remove it
                const updatedCountries = selectedCountriesArray.filter(c => c !== country);
                
                // Ensure at least one country is selected
                if (updatedCountries.length === 0) {
                    // If removing this country would result in no selected countries, return the previous state
                    return prevSelectedCountry;
                }
                
                return updatedCountries.join(',');
            } else {
                // If the country is not selected, add it
                const updatedCountries = [...selectedCountriesArray, country];
                return updatedCountries.join(',');
            }
        });
    
        console.log("Selected Country:", country);
    };
    
    // const handleClick = (country) => {
    //     setSelectedCountry(prevSelectedCountry => {
    //         const selectedCountriesArray = prevSelectedCountry.split(',').filter(c => c);
            
    //         if (selectedCountriesArray.includes(country)) {
    //             // If the country is already selected, remove it
    //             const updatedCountries = selectedCountriesArray.filter(c => c !== country);
    //             return updatedCountries.join(',');
    //         } else {
    //             // If the country is not selected, add it
    //             const updatedCountries = [...selectedCountriesArray, country];
    //             return updatedCountries.join(',');
    //         }
    //     });
    
    //     console.log("Selected Country:", country);
    // };

    console.log("selectedCountry",selectedCountry)
  return (
    <>
        <div className="tabs-header default-data-tab-btns d-flex justify-content-end pe-5 me-lg-3">
        {activeUsersByAgeAndCountrydata?.data?.map((country, index) => (
                    <button
                        key={index}
                        className={`country-button rts___tab ${selectedCountry?.includes(country) ? 'active' : ''}`}
                        onClick={() => handleClick(country)}
                    >
                        {country}
                    </button>
                ))}
            {/* <Tabs activeTab={activeTab} onTabClick={onTabClick} tabArray={activeUsersByAgeAndCountrydata}>
                {tabArray?.map((item,index) =>(
                    <Tab key={index} className={`} item ${activeTab === item.coverId ? 'active' : ''}`}>
                        <div className='data-tab'>
                            {item.tabName}
                        </div>
                    </Tab>
                ))}
            </Tabs> */}
        </div>
        <div className="tabs-body">
                <TabScreen
               >
                { activeUsersByAgeAndCountry && activeUsersByAgeAndCountry?.data && activeUsersByGenderAndCountry && activeUsersByGenderAndCountry?.data ?
                    <DemographicTabItem
                       
                        activeUsersByAgeAndCountry={activeUsersByAgeAndCountry}
                        activeUsersByGenderAndCountry={activeUsersByGenderAndCountry}
                    />
                    :""
                }
                </TabScreen>
        </div>
    </>
  )
}

export default DemographicTabs