import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import johnImage from "../../assets/images/john-cornors.jpg";
import { logout } from "../dataService/DataService";
import { useQuery } from "react-query";
import { getAllWebsites } from "../dataService/DataService";
import Cookies from "js-cookie";
import welcomlogo from "../../assets/images/ozen-logo.svg";
import welcomlogoAhr from "../../assets/images/logo-circle - ahr-text.svg";
import Dropdown from "react-bootstrap/Dropdown";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import { toast } from "react-toastify";
 import { GlobalContext } from "../../globalContext";
 import { useContext } from "react";
const Welcome = () => {
  const {bwebsiteId}=useContext(GlobalContext)
  

  let welcomeMeaasge=""
  if(bwebsiteId==="AHR"){
    welcomeMeaasge="This is a Unified Content Management System (UCMS) for all your websites under the Atmosphere Hotels & Resorts brand. In the grid below, you are shown all the individual websites to which you have been provided access."
  }
  else{
    welcomeMeaasge="This is a Unified Content Management System (UCMS) for all your websites under The OZEN Collection brand. In the grid below, you are shown all the individual websites to which you have been provided access."
  }


  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleNavigateToMenu = (website) => {
    if (website.user_is_active > 0) {
      Cookies.set("websiteId", website.website_id);
      Cookies.set("propertyId", website.property_id);
      Cookies.set("websiteName", website.website);
      Cookies.set("userRole", website.user_role);
      const navigationUrl = website.navigation_url;
      if (!navigationUrl) {
        toast.dismiss();
        toast.error("No module find");
      }
      navigate(navigationUrl);
    } else {
      handleLogout();
    }
  };
  let getAllWebsite = useQuery("AllWebsites", getAllWebsites);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogout = async () => {
    const response = await logout();
    if (response.status === 200) {
      Cookies.remove("token1");
      Cookies.remove("websiteId");
      Cookies.remove("websiteName");
      Cookies.remove("user");
      // Cookies.remove("bwebsiteId");

      navigate("/");
    }
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  const user = getCookie("user");
  if (user == "" || user == null) {
    navigate("/");
  }
  

  return (
    <>
      <div
        id="pageWrapper"
        className="page-wrapper null compact-sidebar compact-small material-icon"
      >
        <header className="page-header d-flex justify-content-between position-fixed bg-white end-0 top-0 z-3 w-100 py-2">
          <div className="left-header d-flex align-items-center">
            <div className="home-logo">
              {
                bwebsiteId==="AHR"?(

                  <img src={welcomlogoAhr} />
                ):(
                  <img src={welcomlogo} />

                )
              }
            </div>
          </div>
          <div className="right-header d-flex align-items-center">
            <div className="atmosphere-hr d-none">
              <select
                className="form-control rounded-1 form-select"
                name=""
                id=""
              >
                <option value="1" defaultValue="Atmosphere H&R">
                  Atmosphere H&R
                </option>
                <option value="2">Atmosphere Kanifushi Maldives</option>
                <option value="3">VARU by Atmosphere</option>
              </select>
            </div>
            <div className="atmosphere-logged-user dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  className="atmosphere-account d-flex align-items-center bg-transparent border-0 p-0"
                  id="dropdown-basic"
                >
                  <div className="profile-image">
                    <span>
                      <img src={johnImage} />
                    </span>
                  </div>
                  <div className="atmosphere-username text-dark">{user}</div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="text-white fw-semibold"
                    onClick={handleLogout}
                  >
                    <span className="user-icon">
                      <i className="fa fa-right-from-bracket"></i>
                    </span>{" "}
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </header>
        <div className="page-body-wrapper">
          <div className="sidebar-wrapper position-fixed top-0 start-0 h-100 d-none">
            <div className="logo-wrapper px-2 py-3 d-flex align-items-center justify-content-center">
              <img
                src="assets/images/white-logo-circle.svg"
                alt="Atmosphere Living"
                title="Atmosphere Living"
              />
            </div>
            <nav className="sidebar-menu-list navbar-brand-box vscroll">
              <ul className="list-unstyled m-0 p-0">
                <li className="sidebar-list">
                  <a
                    href="edit-menues.html"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-bars text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Menu
                    </span>
                  </a>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-th-large text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Page
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Page</li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="manage-pages.html"
                      >
                        <span>
                          <i className="fa fa-file-lines text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Page
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-file-circle-plus text-dark opacity-75"></i>
                        </span>{" "}
                        Add Page
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-file-pen text-dark opacity-75"></i>
                        </span>{" "}
                        Edit Page
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-users text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Users
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Users</li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="roles-&-permissions.html"
                      >
                        <span>
                          <i className="fa fa-user-gear text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Roles
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-users-gear text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Users
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-cart-flatbed-suitcase text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Offers
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Offers</li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="manage-offers.html"
                      >
                        <span>
                          <i className="fa-solid fa-gift text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Offers
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="create-offer.html"
                      >
                        <span>
                          <i className="fa fa-calendar-plus text-dark opacity-75"></i>
                        </span>{" "}
                        Create Offer
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="offer-dashboard.html"
                      >
                        <span>
                          <i className="fa fa-signal text-dark opacity-75"></i>
                        </span>{" "}
                        Performance
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-chart-column text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      leads
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">leads</li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-list-check text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Leads
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-square-plus text-dark opacity-75"></i>
                        </span>{" "}
                        Add Leads
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-bullhorn text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Campaigns
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Campaigns</li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-envelope text-dark opacity-75"></i>
                        </span>{" "}
                        Email Templates
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-share-nodes text-dark opacity-75"></i>
                        </span>{" "}
                        Social Media
                      </a>
                    </li>

                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="offer-dashboard.html"
                      >
                        <span>
                          <i className="fa fa-signal text-dark opacity-75"></i>
                        </span>{" "}
                        Performance
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="manage-album.html"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-images text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Media
                    </span>
                  </a>
                </li>

                <li className="sidebar-list">
                  <a
                    href="press-releases.html"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-newspaper text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Press Releases
                    </span>
                  </a>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa-regular fa-comment-dots text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Enquiries
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Enquiries</li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="contact-us.html">
                        <span>
                          <i className="fa fa-phone text-dark opacity-75"></i>
                        </span>{" "}
                        Contact Us
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-comment-dots text-dark opacity-75"></i>
                        </span>{" "}
                        Messages
                      </a>
                    </li>
                    <li className="main-submenu">
                      <a className="d-flex sidebar-menu" href="#">
                        <span>
                          <i className="fa fa-z text-dark opacity-75"></i>
                        </span>{" "}
                        Zen Desk
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-building text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Properties
                    </span>
                  </a>
                  <ul className="sidebar-submenu custom-scrollbar list-unstyled">
                    <li className="sidebar-head">Properties</li>
                    <li className="main-submenu">
                      <a
                        className="d-flex sidebar-menu"
                        href="manage-properties.html"
                      >
                        <span>
                          <i className="fa fa-building text-dark opacity-75"></i>
                        </span>{" "}
                        Manage Properties
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa fa-layer-group text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      History
                    </span>
                  </a>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa-solid fa-chart-gantt text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Statistics
                    </span>
                  </a>
                </li>
                <li className="sidebar-list">
                  <a
                    href="#"
                    className="sidebar-link w-100 px-2 d-flex align-items-center text-decoration-none"
                  >
                    <span className="menu-icon d-flex justify-content-center">
                      <i className="fa-solid fa-gears text-white"></i>
                    </span>
                    <span className="menu-name d-flex text-white menu-text text-uppercase fw-semibold">
                      Settings
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="page-body ms-0">
            <div className="container-fluid dashboard-default">
              <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                <div className="welcome-wrap">
                  <h4 className="fw-normal m-0">Welcome</h4>
                  <div className="welcome-para mt-lg-3">
                    <p>
                      {welcomeMeaasge}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-3 mt-lg-4 mt-xl-5">
                <div className="col-lg-12">
                  <ul className="dashboards-nav-list grid-view-nav list-unstyled mt-0 mb-0 p-0 d-flex flex-wrap ">
                    {getAllWebsite?.data?.data?.length <= 0 ? (
                      <>
                        <h4 className="align-items-center flex-wrap mt-5">
                          {" "}
                          Either you have not been assigned a role an any
                          website or your role is suspended/inactive. Please
                          contact your admin.
                        </h4>
                      </>
                    ) : (
                      getAllWebsite &&
                      getAllWebsite.data &&
                      getAllWebsite.data.data &&
                      getAllWebsite.data.data.map((websiteData) => (
                        <li key={websiteData.website_id}>
                          <div className="card text-decoration-none">
                            <div className="card-body text-center d-flex align-items-center flex-wrap">
                              <div className="left-nav d-flex align-items-center flex-wrap">
                                <div className="image-nav d-flex align-items-center justify-content-center">
                                  <img
                                    src={websiteData.website_image}
                                    className="w-100"
                                    alt=" "
                                    title=""
                                  />
                                </div>

                                <div className="card-title m-0 text-start mt-4 w-100">
                                  <h5 className="mb-2 fw-semibold">
                                    {websiteData.website}
                                  </h5>
                                  <p className="mb-4">
                                    {websiteData.is_brand
                                      ? "Brand Website"
                                      : "Resort Website"}
                                  </p>
                                </div>

                                <div className="nav-action text-start">
                                  <button
                                    onClick={() =>
                                      handleNavigateToMenu(websiteData)
                                    }
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                  >
                                    Manage
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))
                    )}

                    {getAllWebsite.data && getAllWebsite.data.data.length < 0
                      ? "Either you have not been assigned a role an any webste or your role is suspended/inactive. Please contact your admin."
                      : ""}
                  </ul>
                </div>
                <div className="col-lg-3">
                  <div className="card d-none">
                    <div className="card-body">
                      <div className="support-ticket bg-white">
                        <h5 className="fw-semibold">
                          <strong>X</strong> 24x7 Support Ticket
                        </h5>
                        <div className="card-para mt-3 pb-2">
                          <p>
                            Give us a call 24 hours a day, 7 days a week. Our
                            highly trained, courteous support staff is waiting
                            to take your call.
                          </p>
                        </div>
                        <div className="card-footer bg-transparent pt-4">
                          <div className="contact-now d-flex">
                            <div className="contact-icon">
                              <span className="d-flex align-items-center justify-content-center rounded-3">
                                <i className="fa fa-phone text-white"></i>
                              </span>
                            </div>
                            <div className="contact-text">
                              24/7 Technical support
                              <a
                                className="text-decoration-none fw-semibold"
                                href="tel:(480)5058877"
                              >
                                (480) 505-8877
                              </a>
                            </div>
                          </div>
                          <div className="raise-ticket mt-4">
                            <a
                              href="#"
                              className="btn btn-primary btn-theme fw-semibold text-uppercase w-100 px-3 py-3"
                            >
                              Raise a Ticket
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleShowModal={handleShowModal}
      />
    </>
  );
};

export default Welcome;
