import React, { useState, useEffect } from "react";
import Table from "../../components/Table2";
import { getAllPages } from "../dataService/DataService";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import * as querydata from "../dataService/DataService";
import { useFormik } from "formik";
import * as Yup from "yup";
export const settingsSchema = Yup.object({
  //metaTitle: Yup.string().required("Please enter title"),
  // metaDescription: Yup.string().required("Please enter description"),
  //slug: Yup.string().matches(/^[a-zA-Z0-9_-]*$/,
  //  'Only alphanumeric characters, hyphens, and underscores are allowed'),
  //.required('Please enter slug'),
  //keyWords: Yup.string().required("Please enter keyword"),
});
const ManagePages = () => {
  const navigate = useNavigate();
  const handleNavigateToEdit = (page_id, websiteId) => {

    navigate(`/manage-pages/manage-home-page/${page_id}`, { state: { websiteId: websiteId } });
  };
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pid, setPid] = useState(null);
  const [showSlug, setShowSlug] = useState(true);
  const handleKeyPress = (event) => {
    const enteredChar = event.key;

    // Prevent typing characters other than lowercase letters (a-z) and hyphens (-)
    if (!/^[a-z0-9\-]$/i.test(enteredChar)) {
      event.preventDefault();
    }
  };
  const handleModal = (pageId, pageCode) => {
    setShowModal(!showModal);
    setPid(pageId);
    if (pageCode === "TOCHOME" || pageCode === "ORBHOME" || pageCode === "KHOME" ||
      pageCode === "RHOME" ||
      pageCode === "VHOME" ||
      pageCode === "HOME") {
      setShowSlug(false);
    } else {
      setShowSlug(true);
    }

  };
  const SinglePageData = useQuery(["getSinglePageData", pid], () =>
    querydata.getSinglePageData(pid)
  );

  const initialValues = {
    metaTitle:
      SinglePageData &&
        SinglePageData.data &&
        SinglePageData?.data?.data.meta_title
        ? SinglePageData?.data?.data.meta_title
        : "",
    metaDescription:
      SinglePageData &&
        SinglePageData.data &&
        SinglePageData?.data?.data.meta_description
        ? SinglePageData?.data?.data.meta_description
        : "",
    slug:
      SinglePageData && SinglePageData.data && SinglePageData?.data?.data.slug
        ? SinglePageData?.data?.data.slug
        : "",
    keyWords:
      SinglePageData &&
        SinglePageData.data &&
        SinglePageData?.data?.data.meta_keyword
        ? SinglePageData?.data?.data.meta_keyword
        : "",
  };
  useEffect(() => {
    setValues({ ...initialValues });
  }, [SinglePageData.data]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: settingsSchema,
    onSubmit: (values, action) => {
      updateprojectMutation.mutate({ pid, values });
    },
  });

  const updateprojectMutation = useMutation(querydata.updatePageData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-pages");
      setShowModal(false);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const columns = React.useMemo(
    () => [
      { Header: "Sr.No", accessor: "website_page_id", style: { width: "1px" } },
      { Header: "Page Name", accessor: "page_title" },

      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleNavigateToEdit(row.original.website_page_id, row.original.website_id)}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              onClick={() => handleModal(row.original.website_page_id, row.original.page_code)}
              className="btn btn-primary bg-dark border-dark btn-sm btn-theme ms-1"
            >
              <i className="fa fa-gear"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );
  let allPages = useQuery("getAllPages", getAllPages);
  if (!allPages.data) {
    <div>Loading</div>;
  }
  if (allPages.isLoading) {
    <div>Loading</div>;
  }

  if (!SinglePageData.data) {
    <div>Loading</div>;
  }
  if (SinglePageData.isLoading) {
    <div>Loading</div>;
  }

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0">Manage Pages</h4>

          </div>
          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">Manage Pages</h5>
            </div>

            {allPages.data && (
              allPages.isLoading || !allPages?.data ? (
                <div className="text-center">Loading...</div>
              ) : allPages?.data?.data?.length === 0 ? (
                <div className="card-body text-center">No Data Available</div>
              ) : (
                <>
                  <Table data={allPages?.data?.data} columns={columns} />
                </>
              ))}
          </div>
        </div>
      </div>

      <Modal show={showModal} onHide={handleModal} centered>
        <Modal.Header closeButton className="px-5 py-4">
          <Modal.Title as="h1" className="fs-5">
            Seo Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-5">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="MetaTitle">Meta Title</Form.Label>
              <Form.Control
                type="text"
                id="MetaTitle"
                className="py-3 shadow-none px-4"
                name="metaTitle"
                value={values.metaTitle}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={70}
              />
              {errors.metaTitle && touched.metaTitle ? (
                <p className="form-error text-danger">{errors.metaTitle}</p>
              ) : error.metaTitle ? (
                <p className="form-error text-danger">{error.metaTitle}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="MetaDescription">Description</Form.Label>
              <Form.Control
                as="textarea"
                id="MetaDescription"
                rows="2"
                className="py-3 shadow-none px-4"
                name="metaDescription"
                value={values.metaDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={170}
              />

              {errors.metaDescription && touched.metaDescription ? (
                <p className="form-error text-danger">
                  {errors.metaDescription}
                </p>
              ) : error.metaDescription ? (
                <p className="form-error text-danger">
                  {error.metaDescription}
                </p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="MetaSlug">Slug</Form.Label>
              <Form.Control
                type="text"
                id="MetaSlug"
                className="py-3 shadow-none px-4"
                name="slug"
                placeholder={!showSlug ? "Cannot change for homepage" : ""}
                value={!showSlug ? "" : values.slug}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                autoComplete="off"
                disabled={!showSlug}
              />

              {errors.slug && touched.slug ? (
                <p className="form-error text-danger">{errors.slug}</p>
              ) : error.slug ? (
                <p className="form-error text-danger">{error.slug}</p>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="MetaKeywords">Keywords</Form.Label>
              <Form.Control
                type="text"
                id="MetaKeywords"
                className="py-3 shadow-none px-4"
                name="keyWords"
                value={values.keyWords}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              {errors.keyWords && touched.keyWords ? (
                <p className="form-error text-danger">{errors.keyWords}</p>
              ) : error.keyWords ? (
                <p className="form-error text-danger">{error.keyWords}</p>
              ) : null}
            </Form.Group>
            <div className="text-end">
              <Button
                variant="outline-primary"
                className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManagePages;
