import React, { useState } from "react";

import johnImage from "../assets/images/john-cornors.jpg";
import ChangePasswordModal from "../components/ChangePasswordModal";
import { logout } from "../pages/dataService/DataService";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { IoIosArrowBack, IoIosArrowDropleftCircle, IoIosArrowForward } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { IoArrowBack, IoShareSocial } from "react-icons/io5";
const Header = ({ isSideBarActive, setIsSideBarActive }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };

  // Usage example
  const website_name = getCookie("websiteName");
  const userRole = getCookie("userRole");

  const handlewebsiteredirect = () => {
    //setShowModal(true);

    Cookies.remove("websiteId");
    Cookies.remove("websiteName");

    navigate("/websites-list");
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogout = async () => {
    
    const response = await logout();
  
    if (response.status === 200) {
      
      Cookies.remove("token1");
      Cookies.remove("websiteId");
      Cookies.remove("websiteName");
      Cookies.remove("user");
      // Cookies.remove("bwebsiteId");
      
      navigate("/");
    }
  };
  const user_name = getCookie("user");
  return (
    <header
      id="header"
      className={`page-header d-flex justify-content-end position-fixed bg-white end-0 top-0 flex-wrap ${
        isSideBarActive ? "close_icon" : ""
      } `}
    >
      <div className="left-header d-flex position-absolute h-100 start-0 top-0">
        <div className="stroke-icon toggle-sidebar d-flex align-items-center justify-content-center">
          <a className="h-100 align-items-center d-flex" onClick={() => setIsSideBarActive((prev) => !prev)}>
            <IoIosArrowBack />
          </a>
        </div>
        <div className="header-search w-100 ms-5 d-none">
          <form action="/" method="get">
            <div className="position-relative">
          
            </div>
          </form>
        </div>
      </div>
      {/* <!-- /left-header --> */}
      <div className="right-header d-flex">
      

        <div className="switch-now d-flex align-items-center">
          <span className="opacity-50">Website:</span>
          <div className="toggle-website border-1 overflow-hidden d-flex align-items-center rounded-1 ms-2">
            <span className="px-lg-4 px-2 switchweb">{decodeURIComponent(website_name)}</span>
            <div className="switch-back">
              <a
                onClick={handlewebsiteredirect}
                className="d-flex px-lg-3 px-2 text-white text-decoration-none"
                title="Switch Website"
              >
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        </div>

        <div className="atmosphere-logged-user dropdown">
          <Dropdown>
            <Dropdown.Toggle
              className="atmosphere-account d-flex align-items-center bg-transparent border-0 p-0"
              id="dropdown-basic"
            >
              <div className="profile-image">
                <span>
                  <img src={johnImage} />
                </span>
              </div>
              <div className="atmosphere-username text-dark">
                {user_name}
                <span
                  className="opacity-50 d-block"
                  id="user-role"
                  style={{ fontSize: "14px" }}
                >
                  Role: {userRole}
                </span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
           
            {userRole !== "SuperAdmin" ? ( <Dropdown.Item
                className="text-white fw-semibold"
                href="javascript:void(0);"
                onClick={handleShowModal}
              >
                <span className="user-icon">
                  <i className="fa fa-gear"></i>
                </span>
                Change Password
              </Dropdown.Item>):("")}
             
              <Dropdown.Item
                className="text-white fw-semibold"
                onClick={handleLogout}
                href="#/action-3"
              >
                <span className="user-icon">
                  <i className="fa fa-right-from-bracket"></i>
                </span>{" "}
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <ChangePasswordModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        handleShowModal={handleShowModal}
      />

      <div className="dashboard-action-strip w-100 mt-4 pt-3 position-relative">
        <div className="row">
          <div className="col-6 ps-0">
            <div className="back-to-dashboard">
              <Link to=''><IoArrowBack className="h5 mb-0" /></Link>
            </div>
          </div>
          <div className="col-6">
            <div className="header-right-actions mb-1 mb-md-0 d-flex justify-content-end">
              <button className="btn btn-primary border-0"><IoShareSocial className="me-1" /> Share</button>
            </div>
          </div>
        </div>
      </div>

    </header>
  );
};

export default Header;
